import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import BunnyForm from '../components/Forms/BunnyForm';
import BunnyCsvForm from '../components/Forms/BunnyCsvForm';
import Spinner from '../components/Spinner';
import submitFormData from '../utils/submitFormData';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import PresenceCoverage from '../components/Bunny/PresenceCoverage';
import PlotVisualization, { PlotData } from '../components/BunnyPlot';

export interface FormData {
  from: string;
  to: string;
  vehicle: string[];
  filterBy: string;
  generateCsvs: boolean;
}

const Bunny: React.FC = () => {
  const now = dayjs().tz("America/Toronto");
  const midnight = now.startOf('day');
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isEmptyCsv, setIsEmptyCsv] = useState(false);
  const [isEmptyPlot, setIsEmptyPlot] = useState(false);
  const [vehicleList, setVehicleList] = useState(['Pilot2_BFLG20', 'Pilot2_BFLG22', 'Pilot2_BFLG23',
	 'Pilot2_BFLG24', 'Pilot2_BFLG40', 'Pilot2_JEIL190',
	'Pilot2_BFLG38', 'Pilot2_BFLG41', 'Pilot2_BFLG29', 'Pilot2_BFLG39', 
	'Pilot2_JEIL366', 'Pilot2_JEIL929', 'Pilot2_JEIL948', 'Pilot2_JEIL946',
	'Pilot2_JEIL707', 'Pilot2_JEIL243', 'Pilot4_cookie_monster_dv_adjshelves']);

  const [csvVehicleList, setCsvVehicleList] = useState([])
  const [plotVehicleList, setPlotVehicleList] = useState([])
  const [plotData, setPlotData] = useState<PlotData | null | undefined>()
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [formData, setFormData] = useState<FormData>({
    from: midnight.toISOString(),
    to: now.toISOString(),
    vehicle: [''],
    filterBy: 'All',
    generateCsvs: false,
  });

  const [formDataCsv, setFormDataCsv] = useState<FormData>({
    from: midnight.toISOString(),
    to: now.toISOString(),
    vehicle: [''],
    filterBy: 'All',
    generateCsvs: false,
  });

  useEffect(() => {
	if (plotData && plotData.driving_periods.length !== 0) {
		console.log(plotData.driving_periods)
		setIsEmptyPlot(false);
		setIsModalOpen(true);
	}
	else if (plotData && plotData.driving_periods.length === 0) {
        setIsEmptyPlot(true);
    }
	else {
		setIsModalOpen(false);
	}
  },[plotData]);

  async function handleTimeChange(dateStr: string) {
	try {
	  setIsLoading(true);
	  setIsEmptyPlot(false);
	  setIsEmptyCsv(false);
	  console.log('Submitting form data:', formData);
  
	  const response = await submitFormData(
		{ device_ids: vehicleList, dateStr: dateStr },
		'bunny_uptime'
	  );
  
	  if (response) {
		const newVehicleList = response['valid_device_ids'];
		return newVehicleList;
	  } else {
		console.error('No response received');
		return [];
	  }
	} catch (error) {
	  if (axios.isAxiosError(error)) {
		if (error.response && error.response.status === 401) {
		  alert('Session has expired, please login again.');
		  navigate('/login');
		}
	  } else {
		console.error('Submission failed', error);
	  }
	  return [];
	} finally {
	  setIsLoading(false);
	}
  }

  useEffect(() => {
	console.log(isEmptyCsv)
  },[isEmptyCsv])
  

	useEffect(() => {
		const fetchVehicleList = async () => {
		  const newVehicleList = await handleTimeChange(formData.from);
		  setPlotVehicleList(newVehicleList);
		};
	  
		if (formData.from) {
		  fetchVehicleList();
		}
	  }, [formData.from]);
	  
	  useEffect(() => {
		const fetchCsvVehicleList = async () => {
		  const newVehicleList = await handleTimeChange(formDataCsv.from);
		  setCsvVehicleList(newVehicleList);
		};
	  
		if (formDataCsv.from) {
		  fetchCsvVehicleList();
		}
	  }, [formDataCsv.from]);
	  

  return (
	<div className={`flex flex-col w-fit h-full space-y-3 ${isModalOpen && 'fixed'}`}>
		<div className="p-4 flex flex-row h-fit w-fit space-x-4">
			<div className='flex flex-col h-fit w-fit space-y-4'>
				<span className='w-full h-full self-center text-center font-bold'>
					Generate Route Visualization
				</span>
				<BunnyForm setPlotData={setPlotData} setIsEmpty={setIsEmptyPlot} isLoading={isLoading} setIsLoading={setIsLoading} 
					vehicles={plotVehicleList} 
					setFormData={setFormData} formData={formData}/>
					{isEmptyPlot && <div className='text-red-500'> Query results are empty- try a different day or vehicle </div>}

			</div>
			<div className='flex flex-col h-fit w-fit space-y-4'>
				<span className='w-full h-full self-center text-center font-bold'>
					Generate Route CSV
				</span>
				<BunnyCsvForm setIsEmptyCsv={setIsEmptyCsv} isLoading={isLoading} setIsLoading={setIsLoading} 
					vehicles={csvVehicleList}
					setFormData={setFormDataCsv} formData={formDataCsv}/>
				{isEmptyCsv && <div className='text-red-500'> Query results are empty- try a different day or vehicle </div>}
			</div>
		</div>
		<div className='w-full h-fit flex flex-row justify-center'>
				{isLoading && <Spinner size={60} color="blue" />}
		</div>
		<PresenceCoverage dateStr={formData.from} deviceIds={plotVehicleList}/>
		<PlotVisualization
			isOpen={isModalOpen}
			onClose={() => setIsModalOpen(false)}
			plotData={plotData}
		/>
	</div>
  );
};

export default Bunny;